import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentValutService {
  getMenuVal(): false {
    throw new Error('Method not implemented.');
  }
  selectedDocs: any = [];
  selectFile$: Subject<any> = new Subject<any>();
  selectFolder$: Subject<any> = new Subject<any>();
  parentDirectory: string | undefined;
  documents: any[];
  currentDocuments: any[];
  currentFolder: any;

  constructor(private httpClient: HttpClient) { }

  createFolder(payload: any, folderId: string): Observable<any> {
    return this.httpClient
      .post(
        `${environment.API_URL}/v1/digilocker/${folderId}/create-folder`,
        payload
      )
  }

  updateFolder(payload: any, id: string): Observable<any> {
    return this.httpClient
      .put(
        `${environment.API_URL}/v1/digilocker/${id}/update-folder`,
        payload
      )
  }

  deleteFolder(id: string, params: any = {}): Observable<any> {
    return this.httpClient
      .delete(
        `${environment.API_URL}/v1/digilocker/${id}/delete-folder`,{params}
      );
  }

  createFolderByCompany(payload: any, folderId: string, companyId: string): Observable<any> {
    return this.httpClient
      .post(
        `${environment.API_URL}/v1/digilocker/${companyId}/${folderId}/create-folder`,
        payload
      )
  }


  selectFolder(folder: any) {
    this.currentFolder = { ...folder };
    this.parentDirectory = folder._id;
    this.getList(true);
    this.selectFolder$.next({ id: folder._id });
  }

  getParentDirectory() {
    if (this.parentDirectory) {
      return of(this.parentDirectory);
    } else {
      return this.httpClient
        .get(`${environment.API_URL}/v1/digilocker/get-company-folder`)
        .pipe(
          map((response: any) => {
            return response.data._id;
          }),
          tap((response: string) => {
            this.parentDirectory = response;
          })
        );
    }
  }

  getList(setDetails = false, filters = {}) {
    let query: any = {};
    if (this.parentDirectory) {
      query = {
        ...filters,
      };
    }
    this.getParentDirectory()
      .pipe(
        switchMap(() => {
          query = {
            ...filters,
          };
          return this.httpClient.get(
            `${environment.API_URL}/v1/digilocker?parentDirectory=${this.parentDirectory}`, { params: { ...query } }
          );
        }),
        map((response: any) => {
          let data = response.data;
          return data?.map((folder: any) => {
            return {
              ...folder,
              hasChildren: true,
            };
          });
        })
      )
      .subscribe((folders) => {
        this.updateDocuments(folders);
        if (setDetails) {
          for (let doc of folders) {
            let index = this.selectedDocs.findIndex(
              (item: any) => item._id === doc._id
            );
            if (index !== -1) {
              doc.selected = true;
            }
          }
          this.currentDocuments = folders;
        }
      });
  }

  updateDocuments(folders: any[]) {
    if (this.documents?.length) {
      for (let doc of this.documents) {
        if (doc.type === 'folder') {
          doc.open = false;
        }
      }
    }
    if (!this.currentFolder?.directory?.length) {
      this.documents = folders;
      return;
    }
    let current: any = this.documents;
    let parentArray = [...this.currentFolder.directory];
    parentArray.shift();
    parentArray.push(this.parentDirectory);
    for (let id of parentArray) {
      let index = current.findIndex((doc: any) => doc._id === id);
      if (index !== -1) {
        current[index].open = true;
        if (id === parentArray[parentArray.length - 1]) {
          current[index].documents = folders;
        } else {
          current = current[index].documents;
        }
      } else {
        break;
      }
    }
  }

  uploadFile(file: any, parentDirectory: string, params: any, metaData: any): Observable<any> {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('metaData', JSON.stringify(metaData));
    return this.httpClient.post(`${environment.API_URL}/v1/digilocker/${parentDirectory}/upload`, formData, { params: params })
  }

  damsUploadFile(postData: any, parentDirectory: string, params: any): Observable<any> {
    let formData = new FormData();
    formData.append('file', postData.uploadingFile, postData.uploadingFile.name);
    formData.append('metaData', JSON.stringify(postData.metaData));
    formData.append('companyDocType', postData.companyDocType);
    formData.append('documentNumber', JSON.stringify(postData.formatDetails));
    if (postData?.ocrParsedResultId) {
      formData.append('ocrParsedResultId', postData.ocrParsedResultId)
    }
    return this.httpClient.post(`${environment.API_URL}/v1/digilocker/${parentDirectory}/dams-upload`, formData, { params: params })
  }

  delete(doc: any): Observable<any> {
    return this.httpClient.delete(`${environment.API_URL}/v1/digilocker/document/${doc._id}/delete`);
  }

  rename(doc: any, updateName: any, notTopLevel: any) {
    if (notTopLevel) {
      const rename: Subject<any> = new Subject<any>();
      this.httpClient.put(`${environment.API_URL}/v1/digilocker/document/${doc._id}`, { name: updateName }).subscribe((result: any) => {
        rename.next(result.data);
      });
      return rename;
    } else {
      this.httpClient.put(`${environment.API_URL}/v1/digilocker/document/${doc._id}`, { name: updateName }).subscribe(() => {
        this.getList(true);
      });
      return of()
    }
  }

  downloadFile(doc: any, returnObs: any) {
    let obs: any = this.httpClient.post(`${environment.API_URL}/v1/digilocker/document/${doc._id}/export`, {download: doc.download, forEsign: doc.forEsign},
      { responseType: 'blob', observe: 'response' })
    if (returnObs) {
      return obs;
    } else {
      obs.subscribe((response: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.body);
        link.download = response.headers.get('file-name');
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
      })
    }
  }

  exportEsignDocument(doc: any, returnObs: any) {
    let obs: any = this.httpClient.post(`${environment.API_URL}/v1/digilocker/document/${doc._id}/exportEsignDocument`, { download: doc.download, forEsign: doc.forEsign },
      { responseType: 'blob', observe: 'response' })
    if (returnObs) {
      return obs;
    } else {
      obs.subscribe((response: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.body);
        link.download = response.headers.get('file-name');
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
      })
    }
  }

  downloadFileOnOrg(doc: any, returnObs: any, company: any) {
    let obs: any = this.httpClient.post(`${environment.API_URL}/v1/digilocker/document/${company}/${doc._id}/export`, {download: doc.download, forEsign: doc.forEsign},
      { responseType: 'blob', observe: 'response' })
    if (returnObs) {
      return obs;
    } else {
      obs.subscribe((response: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.body);
        link.download = response.headers.get('file-name');
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
      })
    }
  }

  selectFile(doc: any) {
    if (doc.selected) {
      this.selectedDocs.push(doc);
    } else if (!doc.selected) {
      let index = this.selectedDocs.findIndex((item: any) => item._id === doc._id)
      if (index !== -1) {
        this.selectedDocs.splice(index, 1);
      }
    }
    this.selectFile$.next([...this.selectedDocs]);
  }

  searchFilesInCurrentFolder(filters: any) {
    this.getList(true, filters);
  }

  getRootFolderDirectory(): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/get-company-folder`);
  }

  getFolderByDirectry(directryId: string, params?: any): Observable<any> {
    let endpoint = `digilocker?parentDirectory=${directryId}`
    return this.httpClient.get(`${environment.API_URL}/v1/${endpoint}`, {
      params,
    });
  }

  getFilesInDirectory(company: any, directryId: any, params?: any): Observable<any> {
    let endpoint = `digilocker/${company}/${directryId}`
    return this.httpClient.get(`${environment.API_URL}/v1/${endpoint}`, {
      params,
    });
  }

  editFile(fileId: string, fileDetails: any): Observable<any> {
    return this.httpClient.put(`${environment.API_URL}/v1/digilocker/document/${fileId}`, fileDetails);
  }

  getCompanyStorage(): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/company-storage`);
  }

  getDocumentEsignDetails(documentId: string, params: any = {}): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/document/${documentId}/log`, {params});
  }

  getFileForDocument(documentId: any): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/` + documentId);
  }

  getRootFolderDirectoryByCompany(companyId: string): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/${companyId}/get-company-folder`);
  }

  getFolderByDirectryByCompany(directryId: string, selectedCompanyId: string, params?: any): Observable<any> {
    let endpoint = `digilocker/child-company/${selectedCompanyId}?parentDirectory=${directryId}`
    return this.httpClient.get(`${environment.API_URL}/v1/${endpoint}`, {
      params,
    });
  }

  uploadFileOnOrg(file: any, parentDirectory: string, params: any, metaData: any, selectedOrgId: string): Observable<any> {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('metaData', metaData);
    return this.httpClient.post(`${environment.API_URL}/v1/digilocker/${selectedOrgId}/${parentDirectory}/upload/metadata-folder`, formData, { params: params })
  }

  editFileOnOrg(fileId: string, fileDetails: any, selectedOrgId: any): Observable<any> {
    return this.httpClient.put(`${environment.API_URL}/v1/digilocker/document/${selectedOrgId}/${fileId}`, fileDetails);
  }

  deleteDocOnOrg(doc: any, selectedOrgId: any,): Observable<any> {
    return this.httpClient.delete(`${environment.API_URL}/v1/digilocker/document/${selectedOrgId}/${doc._id}/delete`);
  }

  sendInMail(docId: any, payload: any){
    return this.httpClient.post(`${environment.API_URL}/v1/digilocker/${docId}/send-in-email`, payload);
  }
  sendMultipleFilesToMail(payload: any){
    return this.httpClient.post(`${environment.API_URL}/v1/digilocker/send-in-email`, payload);
  }

  recentUploads(companyId: string):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/document/${companyId}/dashboard/recent-uploads`)
  }

  recentViews(companyId: string, params: any):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/document/${companyId}/dashboard/recent-views`,{
      params: params
    })
  }

  recentActivity(companyId: string, params: any):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/document/${companyId}/dashboard/recent-activity`,{
      params: params
    })
  }

  getCompanyDocTypeAssets(companyId: string,queryparams: any):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/document/${companyId}/dashboard/companydocType-assets`,{
      params: queryparams
    })
  }

  getDocumentStorageDetails(companyId: string,queryparams:any):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/document/${companyId}/dashboard/filtertotal-data`,{
      params: queryparams
    })
  }

  getDocumentNumberFormat(companyDocType : string):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/${companyDocType }/document-number`)
  }

  getEmailSearch(name: string): Observable<any> {
    let url;
    if (name) {
      url = environment.API_URL + `/v1/user/all/child-company-users?name_like=${name}`;
    } else {
      url = environment.API_URL + `/v1/user/all/child-company-users`;
    }
    return this.httpClient.get(url)
  }

  getTrashDocument(companyId: any, rootFolderId: any, params: any):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/${companyId}/${rootFolderId}/trash`, {
      params,
    })
  }

  downloadMultipleDocuments(payload: any):Observable<any>{
    return this.httpClient.post(`${environment.API_URL}/v1/digilocker/documents/export`,payload,  { responseType: 'blob', observe: 'response' })
  }

  getPageDimensions(documentid: any):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/${documentid}/document/page-dimensions`);
  }

  getFileById(documentid: any):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/${documentid}`);
  }

  sendDocToOcr(ocrCategoryId: any, formData: any){
    // return this.httpClient.get(`${environment.API_URL}/v1/analyze-document/672c654a43bd33a8e2386d11`);
    return this.httpClient.post(`${environment.API_URL}/v1/analyze-document/${ocrCategoryId}`, formData);
  }

  saveOcrData(payload: any,ocrId: string){
    return this.httpClient.post(`${environment.API_URL}/v1/analyze-document/${ocrId}/validated`, payload)
  }

  verifyStudent(payload: any,imageId: string){
    return this.httpClient.put(`${environment.API_URL}/v1/images/${imageId}/verify`,payload)
  }

  getOcrFileData(ocrId: string){
    return this.httpClient.get(`${environment.API_URL}/v1/analyze-document/validated/${ocrId}`);
  }

  getFolderDetails(documentId: string, params: any): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/document/${documentId}/log`, {params});
  }

  getSubFolderDetails(documentId:string):Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/${documentId}/folderDetails`);
  }

  getFileBase64(documentId:string):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/digilocker/${documentId}/print`);
  }

}

import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() totalCount: number = 0;
  @Input() showFirstLastIcon : boolean = false;
  @Output() selectedPage :EventEmitter<any> = new EventEmitter();
  rowsPerPage: number = 20;
  hidePagination: boolean = true;
  currentPage: number = 0;
  ngOnChanges(changes: SimpleChanges) {
    if(changes ?.['totalCount']){
      this.totalCount = changes['totalCount'].currentValue || 0;
      this.calculatePages();
    }
  }

  calculatePages(){
    if(!isNaN(this.totalCount) && !isNaN(this.rowsPerPage) && (Number(this.totalCount) > Number(this.rowsPerPage))){
      this.hidePagination = false;
    }else{
      this.hidePagination = true;
    }
  }

  onPageChange(event : any){
    if(this.currentPage !== event?.page){
      this.selectedPage.emit(event);
    }
    this.currentPage = event?.page || 0;
  }
}

import { RouteConstants } from "src/app/share/constants/routes.constants";

export function adminSideBarList(envType: string = 'SDEX') {
  let list: any[] = [
    {
      label: 'Organisations',
      roles: 'company.view',
      routerLink: '/admin/sdex/company',
      hideList: true,
      imgSrc: '../../../assets/Images/side-nav-bar/OrgStructure.svg'
    },
    {
      label: 'Roles',
      roles: 'role.view',
      routerLink: '/roles/role-list',
      hideList: true,
      imgSrc: '../../../assets/Images/side-nav-bar/Roles.svg'
    },
    {
      label: 'Users',
      roles: 'user.view',
      routerLink: '/admin/sub-company-users',
      hideList: true,
      imgSrc: '../../../assets/Images/side-nav-bar/Users.svg'
    }
  ]
  if (envType === 'SDEX') {
    list = list.concat([
      {
        label: 'LR Templates',
        roles: 'lr.assign',
        routerLink: '/admin/admin-templates-list',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/lr-templates.svg'
      },
      {
        label: 'IBA',
        routerLink: '/iba',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/bank.svg'
      }]
    )
  }
  if(envType !== 'CLMS'){
    list.push({
      label: 'Preferences',
      routerLink: '/preferences',
      hideList: true,
      imgSrc: '../../../assets/Images/side-nav-bar/preferences1.png'
    });
  }
  return list;
}

function checkDashboardCondition(userDetails: any, authService: any): boolean{
  let hasDocumentAccess = authService.documentVaultAccess();
  if(!hasDocumentAccess){
    return false;
  }
  let noEsignPermission = userDetails?.disabledPermissions?.includes('e-sign-details');
  if(userDetails?.documentVault?.isExists){
    if( noEsignPermission ||
    ( !noEsignPermission && userDetails?.eSignSelfLink)){
      return true;
    }else if(!noEsignPermission && userDetails?.eSignSelfLink === undefined){
      return false;
    }
  }
  return false;
}

export function companySideBarList(envType: string , isAdmin: boolean, isPrimary: boolean, userDetails: any, authService: any) {
  if (envType === 'SDEX' || !envType) {
    return [
      {
        label: 'Dashboard',
        roles: '',
        routerLink: '/admin/dashboard',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Dashboard.svg'
      },
      {
        label: 'Packages',
        roles: 'package.view',
        modules: 'package.enabled',
        routerLink: '/packages/incoming',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Packages.svg'
      },
      {
        label: ' Document Vault',
        roles: 'document.view',
        modules: 'documentVault.enabled',
        routerLink: 'document-vault',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/DocumentVoult.svg'
      },
      {
        label: 'Network',
        roles: '',
        modules: 'company-contacts.enabled',
        routerLink: '/admin/contact-list',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Network.svg',
      },
      {
        label: 'Search Company',
        roles: '',
        modules: 'ekyc.enabled',
        routerLink: RouteConstants.EKycSearchList,
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Network.svg',
        addBorder: true
      },
      {
        label: 'Users',
        roles: 'user.view',
        routerLink: '/admin/sub-company-users',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Users.svg'
      },
      {
        label: 'Roles',
        roles: 'role.view',
        routerLink: '/roles/role-list',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Roles.svg'
      },
      {
        label: 'Org. Structure',
        roles: 'company.view',
        modules: 'organizationStructure.enabled',
        routerLink: '/organization-structure',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/OrgStructure.svg'
      },
      {
        label: 'LR Templates',
        roles: 'lr.view',
        modules: 'lr.enabled',
        routerLink: '/admin/company-templates-list',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/lr-templates.svg'
      },
      {
        label: 'LR List',
        roles: 'lr.view',
        modules: 'lr.enabled',
        routerLink: '/lr-generation/generated-lrs',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/lr-list.svg'
      },
      {
        label: 'Global Settings',
        roles: true,
        routerLink: '/admin/company-settings',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/settingsImage.svg'
      },
      {
        label: 'e-Way Bill',
        roles: 'ewaybill.view',
        modules: 'package.eWaybill',
        routerLink: '/eway-bill/credentials',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/lock.svg'
      },
      {
        label: 'e-Invoice',
        roles: 'einvoice.view',
        modules: 'package.eInvoice',
        routerLink: '/einvoice',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/invoice.svg'
      }]
  }
  else if (envType === 'DAMS') {
    const damsSidebar = [
      {
        label: 'Dashboard',
        skipAccess: true,
        routerLink: checkDashboardCondition(userDetails, authService) ? 'dams-dashboard' : '/admin/dashboard',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Dashboard.svg'
      },
      {
        label: 'Org. Structure',
        roles: 'company.view',
        routerLink: '/organization-structure',
        modules: 'organizationStructure.enabled',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/OrgStructure.svg'
      },
      {
        label: 'Roles',
        roles: 'role.view',
        routerLink: '/roles/role-list',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Roles.svg'
      },
      {
        label: 'Users',
        roles: 'user.view',
        routerLink: '/admin/sub-company-users',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Users.svg'
      },
      {
        label: 'Document Vault',
        roles: 'document.view',
        routerLink: 'document-vault',
        modules: 'documentVault.enabled',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/DocumentVoult.svg'
      },
      {
        label: 'Student List',
        routerLink: 'student-list',
        modules: 'documentVault.studentCertificate',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Users.svg'
      },
      {
        label: 'Certificates',
        routerLink: 'student-file',
        modules: 'documentVault.studentCertificate',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Users.svg'
      }

    ]
    if(userDetails.isAdmin){
      damsSidebar.splice(4,0,
        {
          label: 'Master Data',
          roles: 'document.view',
          routerLink: '/lookup',
          hideList: true,
          imgSrc: '../../../assets/Images/side-nav-bar/master-data.svg'
        }
      )
    }
    return damsSidebar;
  }
  else if (envType === 'CLMS') {
    if(userDetails?.guestCompany && !userDetails?.company){
      return [
        {
          label: 'Contracts',
          skipAccess: true,
          routerLink: 'contracts',
          hideList: true,
          imgSrc: '../../../assets/Images/side-nav-bar/DocumentVoult.svg',
        },
      ]
    }
    return [
      {
        label: 'Dashboard',
        roles: '',
        routerLink: '/admin/dashboard',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Dashboard.svg'
      },
      {
        label: 'Org. Structure',
        roles: 'company.view',
        routerLink: '/organization-structure',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/OrgStructure.svg'
      },
      {
        label: 'Roles',
        roles: 'role.view',
        routerLink: '/roles/role-list',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Roles.svg',
      },
      {
        label: 'Users',
        roles: 'user.view',
        routerLink: '/admin/sub-company-users',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/Users.svg',
        addBorder: true
      },

      {
        label: 'Network',
        roles: 'guest-company.view',
        routerLink: '/contacts',
        hideList: isAdmin,
        imgSrc: '../../../assets/Images/side-nav-bar/Network.svg'
      },
      {
        label: 'Contracts',
        roles: 'contracts.view',
        routerLink: 'contracts',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/DocumentVoult.svg',
      },
      {
        label: 'Preferences',
        roles: true,
        routerLink: 'cms-preference',
        hideList: isAdmin,
        imgSrc: '../../../assets/Images/side-nav-bar/preferences1.png'
      },
      {
        label: 'Global Settings',
        roles: true,
        routerLink: '/admin/company-settings',
        hideList: true,
        imgSrc: '../../../assets/Images/side-nav-bar/settingsImage.svg'
      },
    ]
  }
  return [];
}

<div *ngIf="!hidePagination">
  <p-paginator
    [first]="1"
    [rows]="rowsPerPage"
    [totalRecords]="totalCount"
    [showFirstLastIcon]="showFirstLastIcon"
    styleClass="paginator"
    (onPageChange)="onPageChange($event)">
  </p-paginator>
</div>
